import React from "react";

import CMSInput from "components/common/CMSInput";

import SearchIcon from "../../assets/icons/search-black.svg";

const CMSAddContactForm = ({ open, close }) => {
  return (
    <div className="edit-contact-form">
      <div className="cms-field-block-with-numbers">
        <span className="position-number">1.</span>
        <div className="cms-form-group">
          <label htmlFor="searchcontact" className="cms-form-label">
            Search contact
          </label>
          <div className="cms-input-group">
            <img
              src={SearchIcon}
              alt="search"
              width="24"
              height="24"
              className="floating-icon"
            />
            <input
              type="text"
              id="searchcontact"
              className="cms-form-field"
              value=""
              placeholder="Search person by name, eail address..."
            />
          </div>
        </div>
      </div>
      <div className="cms-field-block-with-numbers">
        <span className="position-number">2.</span>
        <CMSInput
          id="name"
          type="text"
          value=""
          placeholder="Full Name"
          disabled={true}
          labelName="Name"
        />
      </div>
      <div className="cms-field-block-with-numbers">
        <span className="position-number">3.</span>
        <CMSInput
          id="position"
          type="text"
          value=""
          placeholder="Regional Leadership"
          disabled={true}
          labelName="Position at BCG"
        />
      </div>
      <div className="cms-field-block-with-numbers">
        <span className="position-number">4.</span>
        <CMSInput
          id="homeoffice"
          type="text"
          value=""
          placeholder="Home office"
          disabled={true}
          labelName="Home office"
        />
      </div>
      <div className="cms-field-block-with-numbers">
        <span className="position-number">5.</span>
        <CMSInput
          id="currentofficelocation"
          type="text"
          value=""
          placeholder="Current office location"
          disabled={true}
          labelName="Current office location"
        />
      </div>
      <div className="cms-field-block-with-numbers">
        <span className="position-number">6.</span>
        <div className="cms-form-group">
          <label htmlFor="mobile" className="cms-form-label">
            Mobile
          </label>
          <input
            type="text"
            id="mobile"
            className="cms-form-field"
            value=""
            placeholder="mobile"
            disabled={true}
          />
        </div>
      </div>
      <div className="cms-field-block-with-numbers">
        <span className="position-number">7.</span>
        <div className="cms-form-group">
          <label htmlFor="fax" className="cms-form-label">
            Fax
          </label>
          <input
            type="text"
            id="fax"
            className="cms-form-field"
            value=""
            placeholder="Fax"
            disabled={true}
          />
        </div>
      </div>
      <div className="cms-field-block-with-numbers">
        <span className="position-number">8.</span>
        <div className="cms-form-group">
          <label
            htmlFor="contactassociatedwithpages"
            className="cms-form-label"
          >
            Contact Associated with pages
          </label>
          <div className="cms-select-field">
            <select
              id="contactassociatedwithpages"
              className="cms-form-field"
              value=""
            >
              <option value="">Industial Goods</option>
              <option value="">Industial Goods</option>
              <option value="">Industial Goods</option>
            </select>
          </div>
        </div>
      </div>
      <div className="cms-field-block-with-numbers">
        <span className="position-number">9.</span>
        <div className="cms-form-group">
          <label htmlFor="rolecategory" className="cms-form-label">
            Role Category
          </label>
          <div className="cms-select-field">
            <select id="rolecategory" className="cms-form-field" value="">
              <option value="">Sector & Topic Leadership</option>
              <option value="">Industial Goods</option>
              <option value="">Industial Goods</option>
            </select>
          </div>
        </div>
      </div>
      <div className="cms-field-block-with-numbers">
        <span className="position-number">10.</span>
        <div className="cms-form-group">
          <label htmlFor="rolegroup" className="cms-form-label">
            Role Group
          </label>
          <div className="cms-select-field">
            <select id="rolegroup" className="cms-form-field" value="">
              <option value="">Global Sector Leaders</option>
              <option value="">Industial Goods</option>
              <option value="">Industial Goods</option>
            </select>
          </div>
        </div>
      </div>
      <div className="cms-field-block-with-numbers">
        <span className="position-number">11.</span>
        <div className="cms-form-group">
          <label htmlFor="role" className="cms-form-label">
            Role
          </label>
          <div className="cms-select-field">
            <select id="role" className="cms-form-field" value="">
              <option value="">Lead Knowledge Analyst</option>
              <option value="">Industial Goods</option>
              <option value="">Industial Goods</option>
            </select>
          </div>
        </div>
      </div>
      <div className="cms-field-block-with-numbers">
        <span className="position-number">12.</span>
        <div className="cms-form-group">
          <label htmlFor="roledetails" className="cms-form-label">
            Role Details (Expertise)
          </label>
          <div className="cms-select-field">
            <select id="roledetails" className="cms-form-field" value="">
              <option value="">Innovation</option>
              <option value="">Industial Goods</option>
              <option value="">Industial Goods</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CMSAddContactForm;
