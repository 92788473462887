import React from "react";
import CMSContactCard from "../../components/CMSContactCard";

const CMSAccessManagementAdmins = () => {
  return (
    <div className="access-management">
    <div className="access-management__heading">CURRENT ADMINS</div>
    <div className="access-management__admin_list">
        <CMSContactCard />
        <CMSContactCard />
    </div>
</div>
  );
};

export default CMSAccessManagementAdmins;
