import React from "react";

const CMSInput = ({ id, type, value, placeholder, disabled, labelName }) => {
  return (
    <div className="cms-form-group">
      <label htmlFor={id} className="cms-form-label">
        {labelName}
      </label>
      <input
        type={type}
        id={id}
        className="cms-form-field"
        value={value}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};

CMSInput.defaultProps = {
  disabled: false,
  labelName: "Add Label Name",
  placeholder: "Add placeholder value",
  type: "text",
};

export default CMSInput;
