const Contacts = {
    "contacts": [
        {
            "name" : "Uttam Kumar Rapeti",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation df",
            "id": 1
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 2
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 3
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 4
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 5
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 6
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 7
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 8
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 9
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 10
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 11
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 12
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 13
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 14
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 15
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 16
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 17
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 18
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 19
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 20
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 21
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 22
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 23
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 24
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 25
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 26
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 27
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 28
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 29
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 30
        },
        {
            "name" : "Abhishek Sharma",
            "hrTitle": "Product Manager",
            "position" : "Principal",
            "email": "sharma.abhishek@bcg.com",
            "homeOffice": "Regional Leadership",
            "currentOffice": "Regional Leadership",
            "category" : "Sector & Topic Leadership",
            "group": "Global Sector Leaders",
            "role" : "Lead Knowledge Analyst",
            "roleDetails": "Innovation",
            "id": 31
        }
    ]
}

export default Contacts;