import React from "react";
import CMSRoleCards from "components/CMSRole/CMSRoleCard";
import Filters from "components/CMSRole/CMSRoleFilters";
import ReactPaginate from 'react-paginate';
import ArrowRight from 'assets/icons/arrow-right.svg';

const CMSRoleGroup = () => {
  const roleGroupFiltersData = {
    name: "ROLE GROUP",
    searchPlaceholder: "Search by Role Group or Roles...",
    filterOne: {
      filterLabel: "ROLE GROUP",
      filterData: [
        { id: 1, name: "lorem" },
        { id: 2, name: "ipsum" },
      ],
      filterClassName: "roleGroup",
      filterPlaceholder: "Search Role Group",
    },
    filterTwo: {
      filterLabel: "ROLES",
      filterData: [
        { id: 3, name: "dummy" },
        { id: 4, name: "option" },
      ],
      filterClassName: "roles",
      filterPlaceholder: "Search Roles",
    },
    addBtn: "NEW GROUP",
    addModal: {
      modalHeader: "ADD NEW ROLE GROUP",
      addModalFieldFlag: true,
      fieldOne: {
        modalLabel: "Name of Role Group",
        modalLabelPlaceholder: "Enter role group name..",
      },
      filedTwo: {
        modalLabel: "Associate Role group with",
        modalLabelPlaceholder: "Select Role Group.."
      }
    },
    deleteModalMessage:
      "Are you sure you want to delete the selected role group? Once deleted the role category will stop appearing on all the associated pages.",
  };
  const ArrowLeftImage = () => {
    return <span>←</span>
  }
  const ArrowRightImage = () => {
    return <span>→</span>
  }
  return (
    <>
      <Filters filtersData={roleGroupFiltersData} />
      <div className="d-flex-only flex-column">
        <CMSRoleCards name="Role Group Name" categories="Roles within Role Group" pages="Role Group displayed on the pages"/>
        <CMSRoleCards name="Role Group Name" categories="Roles within Role Group" pages="Role Group displayed on the pages" />
        <CMSRoleCards name="Role Group Name" categories="Roles within Role Group" pages="Role Group displayed on the pages" />
        <CMSRoleCards name="Role Group Name" categories="Roles within Role Group" pages="Role Group displayed on the pages" />
        <CMSRoleCards name="Role Group Name" categories="Roles within Role Group" pages="Role Group displayed on the pages" />
        <CMSRoleCards name="Role Group Name" categories="Roles within Role Group" pages="Role Group displayed on the pages" />
        <CMSRoleCards name="Role Group Name" categories="Roles within Role Group" pages="Role Group displayed on the pages" />
        <CMSRoleCards name="Role Group Name" categories="Roles within Role Group" pages="Role Group displayed on the pages" />
      </div>
      <ReactPaginate
          previousLabel={<ArrowLeftImage/>}
          nextLabel={<ArrowRightImage/>}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={20}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={() => { }}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
    </>
  );
};

export default CMSRoleGroup;
