import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CMSDashboard from "containers/CMSDashboard";
import About from "containers/About";
import "assets/index.scss";

function App() {
  return (
    <Router >
      <Switch>
        <Route path="/" component={CMSDashboard} exact />
        <Route path="/about" component={About} />
      </Switch>
    </Router>
  );
}

export default App;
