import React from "react";
import Modal from "react-modal";


import "./style.scss";

const CMSModal = ({ open, close, size, children, className, modalTitle, headerFaIcon }) => {
  return (
    <Modal
      isOpen={open}
      className={`${
        size === "small" ? "cms-react-modal small " : "cms-react-modal large "
      } ${className}`}
      ariaHideApp={false}
      onAfterOpen={() => (document.body.style.overflow = "hidden")}
      onAfterClose={() => (document.body.style.overflow = "auto")}
    >
      {size === "small" ? (
        children
      ) : (
        <div className="large-modal">
          <div className="modal-header">
            <div className="d-flex-align">
              <div className="header-icon-panel">
                <img src={headerFaIcon} alt="headericon" width="14" height="14" />
              </div>
              <h2 className="modal-header-title">
                {modalTitle}
              </h2>
            </div>

            <button
              onClick={(open) => close(!open)}
              type="button"
              className="cms-close-icon"
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            <div className="cms-button-group">
              <button
                type="button"
                className="cms-default-btn"
                onClick={(open) => close(!open)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="cms-primary-btn"
                onClick={(open) => close(!open)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

CMSModal.defaultProps = {
  size: "large",
  title: "Add your modal title",
  cancelbuttonName: "Close",
  saveButtonName: "Save",
};

export default CMSModal;
