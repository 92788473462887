import React, { useState } from "react";

import CMSContactTableBody from "./CMSContactTableBody";
import CMSModal from "components/CMSModal";
import CMSEditContactForm from "./CMSEditContactForm";
import ReactPaginate from 'react-paginate';
import ArrowRight from 'assets/icons/arrow-right.svg';

import DeleteAlert from "../../assets/icons/Delete-red.svg";
import headerIcon from "../../assets/icons/plus-black.svg";

import "./style.scss";

const CMSContactTabel = () => {
  const [deleteContactModal, setDeleteContactModal] = useState(false);
  const [editContactModal, setEditContactModal] = useState(false);

  const ArrowLeftImage = () => {
    return <span>←</span>
  }
  const ArrowRightImage = () => {
    return <span>→</span>
  }
  return (
    <>
    <div className="contacts-result scroll-bar">
      <table className="cms-table-root" border="0">
        <thead className="cms-table-head">
          <tr className="cms-table-row">
            <th className="cms-table-cell cms-table-cell-head  stickyHeader">
              Name
            </th>
            <th className="cms-table-cell cms-table-cell-head stickyHeader">
              HR Title
            </th>
            <th className="cms-table-cell cms-table-cell-head stickyHeader">
              Position
            </th>
            <th className="cms-table-cell cms-table-cell-head stickyHeader">
              Email address
            </th>
            <th className="cms-table-cell cms-table-cell-head stickyHeader">
              Home office
            </th>
            <th className="cms-table-cell cms-table-cell-head stickyHeader">
              Current office
            </th>
            <th className="cms-table-cell cms-table-cell-head stickyHeader">
              Role Category
            </th>
            <th className="cms-table-cell cms-table-cell-head stickyHeader">
              Role Group
            </th>
            <th className="cms-table-cell cms-table-cell-head stickyHeader">
              Role
            </th>
            <th className="cms-table-cell cms-table-cell-head stickyHeader">
              Role Details <br />
              (Expertise)
            </th>
          </tr>
        </thead>
        <CMSContactTableBody
          deleteContact={() => setDeleteContactModal(!deleteContactModal)}
          editContact={() => setEditContactModal(!editContactModal)}
        />
      </table>
      
      {deleteContactModal && (
        <CMSModal
          open={deleteContactModal}
          close={() => setDeleteContactModal(!deleteContactModal)}
          size="small"
          className="small-modal"
        >
          <div className="d-flex-only">
            <div className="delete-block">
              <img src={DeleteAlert} alt="delete" width="14" height="18px" />
            </div>

            <p>
              Are you sure you want to delete the contact? Once deleted this
              contact will stop appearing on all the associated pages. Please
              confirm to proceed
            </p>
          </div>
          <div className="cms-button-group">
            <button
              type="button"
              className="cms-default-btn"
              onClick={() => setDeleteContactModal(!deleteContactModal)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="cms-danger-btn"
              onClick={() => setDeleteContactModal(!deleteContactModal)}
            >
              Delete
            </button>
          </div>
        </CMSModal>
      )}
      {editContactModal && (
        <CMSModal
          size="large"
          open={editContactModal}
          close={() => setEditContactModal(!editContactModal)}
          className="large-modal"
          modalTitle="Associate new contact with Topic page"
          headerFaIcon={headerIcon}
        >
          <CMSEditContactForm />
        </CMSModal>
      )}
    </div>
    <ReactPaginate
          previousLabel={<ArrowLeftImage/>}
          nextLabel={<ArrowRightImage/>}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={20}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={() => { }}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
    </>
  );
};

export default CMSContactTabel;
