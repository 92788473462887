import React from "react";
import searchIcon from "assets/icons/searchIcon.svg";

import "./style.scss";

const InputOnTabs = ({ placeholder }) => {
  return (
    <div className="contacts-search">
      <img src={searchIcon} className="search-icon" alt="search" />
      <input
        type="text"
        className="contacts-search-input"
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputOnTabs;
