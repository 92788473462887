import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CMSRole from "../../containers/CMSRole";
import CMSContact from "../../containers/CMSContact";
import CMSAccessManagement from "../../containers/CMSAccessManagement";
import "./style.scss";

const CMSTabs = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <Tabs
      className="cms-tabs"
      selectedIndex={tabIndex}
      onSelect={(index) => setTabIndex(index)}
    >
      <TabList>
        <div className="container d-flex-only">
          <Tab>Manage Contacts</Tab>
          <Tab>Manage Roles</Tab>
          <Tab>Access Management</Tab>
        </div>
      </TabList>
      <TabPanel>
        <div className="container d-flex-only">
         <CMSContact/>
        </div>
      </TabPanel>
      <TabPanel>
        <CMSRole />
      </TabPanel>
      <TabPanel>
        <div className="container d-flex-only">
          <CMSAccessManagement />
        </div>
      </TabPanel>
    </Tabs>
  );
};

export default CMSTabs;
