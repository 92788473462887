import React from "react";
import CMSInputWithIcon from "./components/CMSInputWithIcon";
import MultiSelectCheckbox from "../common/MultiSelectCheckbox";

import RoleCategoryList from "./components/RoleCategoryList";

const CMSRolePages = ({ globalButton, paLevelButton, globalEditAction }) => {
  return (
    <div className="CMSRolePages-container">
      {globalButton && (
        <div className="CMSRolePages-header">
          <div
            className="CMSRolePages-header-item"
            style={{
              padding: `${globalEditAction ? "0" : "16px 0 16px 18px"}`,
            }}
          >
            {globalEditAction && (
              <CMSInputWithIcon type="text" placeholder="Role Category" />
            )}
            {!globalEditAction && "Role Category"}
          </div>
          <div
            className="CMSRolePages-header-item"
            style={{
              padding: `${globalEditAction ? "0" : "16px 0 16px 18px"}`,
            }}
          >
            {globalEditAction && (
              <CMSInputWithIcon type="text" placeholder="Role Group" />
            )}
            {!globalEditAction && "Role Group"}
          </div>
          <div
            className="CMSRolePages-header-item"
            style={{
              padding: `${globalEditAction ? "0" : "16px 0 16px 18px"}`,
            }}
          >
            {globalEditAction && (
              <CMSInputWithIcon type="text" placeholder="Roles" />
            )}
            {!globalEditAction && "Roles"}
          </div>
        </div>
      )}
      {paLevelButton && (
        <div className="CMSRolePages-header">
          <div className="CMSRolePages-header-item" style={{
              width: `358px`,
            }}>
            <CMSInputWithIcon type="text" placeholder="Search PA Sectors/Topics" />
          </div>
          <div className="CMSRolePages-header-item" style={{
              width: `218px`,
            }}>
          <MultiSelectCheckbox
            data={[
              { id: 1, name: "option 1" },
              { id: 2, name: "option 2" },
            ]}
            labelTxt={"INDUSTRY"}
            prefillData={[]}
            className={"practiceArea"}
            placeholder={"Industry"}
            sendSelectedData={() => { return  {id: 1 }}}
            isSingle={true}
          /></div>
          <div className="CMSRolePages-header-item" style={{
              width: `218px`,
            }}>
          <MultiSelectCheckbox
            data={[
              { id: 1, name: "option 1" },
              { id: 2, name: "option 2" },
            ]}
            labelTxt={"FUNCTIONAL"}
            prefillData={[]}
            className={"practiceArea"}
            placeholder={"FUNCTIONAL"}
            sendSelectedData={() => { return  {id: 1 }}}
            isSingle={true}
          /></div>
        </div>
      )}

      <div className="CMSRolePages-list-group">
      <RoleCategoryList/>
        <div className="CMSRolePages-list-group-items">
          <div className="CMSRolePages-list-group-item">
            Global Leadership(15)
          </div>
          <div className="CMSRolePages-list-group-item">
            Global Leadership(15)
          </div>
          <div className="CMSRolePages-list-group-item">
            Global Leadership(15)
          </div>
          <div className="CMSRolePages-list-group-item">
            Global Leadership(15)
          </div>
          <div className="CMSRolePages-list-group-item">
            Global Leadership(15)
          </div>
        </div>
        <div className="CMSRolePages-list-group-items">
          <div className="CMSRolePages-list-group-item">
            Global Practice Area Leader
          </div>
          <div className="CMSRolePages-list-group-item">
            Global Practice Area Leader
          </div>
          <div className="CMSRolePages-list-group-item">
            Global Practice Area Leader
          </div>
          <div className="CMSRolePages-list-group-item">
            Global Practice Area Leader
          </div>
        </div>
      </div>
    </div>
  );
};

export default CMSRolePages;
