import React from "react";
import CMSAccessManagementCard from "./CMSAccessManagCard";
import ReactPaginate from "react-paginate";

const CMSAccessManagmentCards = ({requestData}) => {
  const requestRowData = [
    "Request Type",
    "Request Details.",
    "Request Raised By",
    "Date",
    "Status",
    "Comment",
  ];
  const ArrowLeftImage = () => {
    return <span>←</span>;
  };
  const ArrowRightImage = () => {
    return <span>→</span>;
  };
  return (
    <>
      <div className="d-flex-only flex-column">
        <CMSAccessManagementCard rows={requestRowData} content={requestData}/>
      </div>
      <ReactPaginate
        previousLabel={<ArrowLeftImage />}
        nextLabel={<ArrowRightImage />}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={20}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={() => {}}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    </>
  );
};

export default CMSAccessManagmentCards;
