import React, { useState } from "react";
import CMSCard from "components/CMSRole/CMSCard";
import CMSRoleCategory from "components/CMSRoleCategory/CMSRoleCategory";
import CMSRoleGroup from "components/CMSRoleGroup/CMSRoleGroup";
import CMSRoles from "components/CMSRoles/CMSRoles";
import CMSRoleDetails from "components/CMSRoleDetails/CMSRoleDetails";
import CMSRoleStructure from "components/CMSRoleStructure";

import HomeIcon from "assets/icons/home.png";
import "./style.scss";

const CMSRole = () => {
  const [innerTile, setInnerTile] = useState(false);
  const [roleCategory, setRoleCategory] = useState(false);
  const [roleGroup, setRoleGroup] = useState(false);
  const [roles, setRoles] = useState(false);
  const [roleDetails, setRoleDetails] = useState(false);
  const [roleStructure, setRoleStructure] = useState(false);
  return (
    <div className="container">
      {!innerTile && (
        <>
          <div className="CMSRole-Headline">
            <img src={HomeIcon} alt="home" width="20" height="16" />
            <h2 className="text-uppercase">Manage Roles</h2>
          </div>
          <div className="d-flex-align flex-wrap">
            <CMSCard
              // viewCard={() => setInnerTile(!innerTile)}
              viewCard={() => {
                setRoleCategory(!roleCategory);
                setInnerTile(!innerTile);
              }}
              name="Role Category"
              description="Tum dicere exorsus est eligendi optio, cumque nihil ut calere ignem, nivem esse nihil ut calere ignem, nivem esse."
            />
            <CMSCard
              viewCard={() => {
                setRoleGroup(!roleGroup);
                setInnerTile(!innerTile);
              }}
              name="Role Group"
              description="Tum dicere exorsus est eligendi optio, cumque nihil ut calere ignem, nivem esse nihil ut calere ignem, nivem esse."
            />
            <CMSCard
              viewCard={() => {
                setRoles(!roles);
                setInnerTile(!innerTile);
              }}
              name="Roles"
              description="Tum dicere exorsus est eligendi optio, cumque nihil ut calere ignem, nivem esse nihil ut calere ignem, nivem esse."
            />
            <CMSCard
              viewCard={() => {
                setRoleDetails(!roleDetails);
                setInnerTile(!innerTile);
              }}
              name="Role Details"
              description="Tum dicere exorsus est eligendi optio, cumque nihil ut calere ignem, nivem esse nihil ut calere ignem, nivem esse."
            />
            <CMSCard
              viewCard={() => {
                setRoleStructure(!roleStructure);
                setInnerTile(!innerTile);
              }}
              name="Manage Role Structure "
              description="Tum dicere exorsus est eligendi optio, cumque nihil ut calere ignem, nivem esse nihil ut calere ignem, nivem esse."
            />
          </div>
        </>
      )}
      {innerTile && roleCategory && <CMSRoleCategory />}
      {innerTile && roleGroup && <CMSRoleGroup />}
      {innerTile && roles && <CMSRoles />}
      {innerTile && roleDetails && <CMSRoleDetails />}
      {innerTile && roleStructure && <CMSRoleStructure />}
    </div>
  );
};

export default CMSRole;
