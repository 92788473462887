import React, { useState } from "react";
import CMSAccessManagmentCards from "../../components/CMSAccessManagmentTabel";
import CMSAccessManagementAdmins from "./Admins";
import CMSAccessManagementLogs from "./Logs";
import "./style.scss";

const requestOpenTableData = [
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "open",
    field: "textarea",
  },
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "open",
    field: "textarea",
  },
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "open",
    field: "textarea",
  },
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "open",
    field: "textarea",
  },
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "open",
    field: "textarea",
  },
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "open",
    field: "textarea",
  },
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "open",
    field: "textarea",
  },
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "open",
    field: "textarea",
  },
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "open",
    field: "textarea",
  },
];

const requestCloseTableData = [
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "close",
    field: "textarea",
  },
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "close",
    field: "textarea",
  },
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "close",
    field: "textarea",
  },
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "close",
    field: "textarea",
  },
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "close",
    field: "textarea",
  },
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "close",
    field: "textarea",
  },
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "close",
    field: "textarea",
  },
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "close",
    field: "textarea",
  },
  {
    type: "Request for new role group",
    details:
      "Primum igitur, inquit, sic agam, ut ad modum, quaeso, interpretaris? sicine eos censes. In oculis quidem rerum hic tenetur a sapiente delectus, ut ratione intellegi posse. Ut placet, ",
    raisedBy: "Jackson Rogers@bcg.com",
    date: "31 May 2021",
    status: "close",
    field: "textarea",
  },
];

const CMSAccessManagement = () => {
  const [pills, setPills] = useState({
    open: true,
    closed: false,
    logs: false,
    admin: false,
  });
  return (
    <div className="access-management">
      <div className="access-management__pills">
        <button
          type="button"
          className={`access-management__pills__button ${
            pills.open ? "access-management__pills__active" : ""
          }`}
          onClick={() => {
            pills.open
              ? setPills({ ...pills })
              : setPills({ open: true, closed: false, admin: false, logs: false });
          }}
        >
          Open Requests - 08
        </button>
        <button
          type="button"
          className={`access-management__pills__button ${
            pills.closed ? "access-management__pills__active" : ""
          }`}
          onClick={() => {
            pills.closed
              ? setPills({ ...pills })
              : setPills({ open: false, closed: true, admin: false, logs: false });
          }}
        >
          Closed Requests - 04
        </button>
        <button
          type="button"
          className={`access-management__pills__button ${
            pills.logs ? "access-management__pills__active" : ""
          }`}
          onClick={() => {
            pills.closed
              ? setPills({ ...pills })
              : setPills({ open: false, closed: false, admin: false, logs: true });
          }}
        >
          Logs
        </button>
        <button
          type="button"
          className={`access-management__pills__button ${
            pills.admin ? "access-management__pills__active" : ""
          }`}
          onClick={() => {
            pills.admin
              ? setPills({ ...pills })
              : setPills({ open: false, closed: false, admin: true, logs: false });
          }}
        >
          Admin - 01
        </button>
      </div>
      <div className="access-management__contacts-list">
        {pills.open && (
          <CMSAccessManagmentCards requestData={requestOpenTableData} />
        )}
        {pills.closed && (
          <CMSAccessManagmentCards requestData={requestCloseTableData} />
        )}
        {pills.logs && (
          <CMSAccessManagementLogs/>
        )}
        {pills.admin && <CMSAccessManagementAdmins />}
      </div>
    </div>
  );
};

export default CMSAccessManagement;
