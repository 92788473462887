import React from "react";
import CMSRoleCards from "components/CMSRole/CMSRoleCard";
import Filters from "components/CMSRole/CMSRoleFilters";

const CMSRoles = () => {
  const rolesFiltersData = {
    name: "ROLE",
    searchPlaceholder: "Search by Role name or role group..",
    filterOne: {
      filterLabel: "ROLES",
      filterData: [
        { id: 1, name: "lorem" },
        { id: 2, name: "ipsum" },
      ],
      filterClassName: "roles",
      filterPlaceholder: "Search Roles",
    },
    filterTwo: {
      filterLabel: "ROLE GROUP",
      filterData: [
        { id: 3, name: "dummy" },
        { id: 4, name: "option" },
      ],
      filterClassName: "roleGroup",
      filterPlaceholder: "Search Role Group",
    },
    addBtn: "NEW ROLE",
    addModal: {
        modalHeader: "ADD NEW ROLE",
        addModalFieldFlag: true,
        fieldOne: {
            modalLabel: "Associate Role with",
            modalLabelPlaceholder: "Enter role name.."
        },
        filedTwo: {
            modalLabel: "Name of Role",
            modalLabelPlaceholder: "Select Role Group.."
        }
    },
    deleteModalMessage:
      "Are you sure you want to delete the selected role? Once deleted the role category will stop appearing on all the associated pages.",
  };
  return (
    <>
      <Filters filtersData={rolesFiltersData} />
      <div className="d-flex-only flex-column">
        <CMSRoleCards name="Role Name" categories="Role within Group" pages="Number of users assigned this role" rolesUser />
        <CMSRoleCards name="Role Name" categories="Role within Group" pages="Number of users assigned this role" rolesUser />
        <CMSRoleCards name="Role Name" categories="Role within Group" pages="Number of users assigned this role" rolesUser />
        <CMSRoleCards name="Role Name" categories="Role within Group" pages="Number of users assigned this role" rolesUser />
        <CMSRoleCards name="Role Name" categories="Role within Group" pages="Number of users assigned this role" rolesUser />
        <CMSRoleCards name="Role Name" categories="Role within Group" pages="Number of users assigned this role" rolesUser />
        <CMSRoleCards name="Role Name" categories="Role within Group" pages="Number of users assigned this role" rolesUser />
        <CMSRoleCards name="Role Name" categories="Role within Group" pages="Number of users assigned this role" rolesUser />
      </div>
    </>
  );
};

export default CMSRoles;
