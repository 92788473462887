import React, { useState } from "react";
import CMSModal from "../CMSModal";
import CMSInput from "components/common/CMSInput";

import DownArrow from "assets/icons/arrowDown-green.svg";
import DeleteIcon from "../../assets/icons/Delete-green.svg";
import DeleteAlert from "../../assets/icons/Delete-red.svg";
import EditGreenIcon from "../../assets/icons/edit-green.svg";
import EditWhiteIcon from "../../assets/icons/edit-white.svg";

const cmsGroups = [
  "Global Leadership",
  "Regional Leadership",
  "Regional Leadership - NAMR",
  "Industrial Goods",
  "Regional Leadership",
  "Marketing, Sales & Pricing",
  "Regional Leadership",
  "Marketing, Sales & Pricing",
];

const cmsPages = [
  "Strategy and sustainability",
  "Energy",
  "Insurance",
  "Global Leadership",
  "Regional Leadership",
  "Global Leadership",
  "Regional Leadership",
  "Global Leadership",
  "Regional Leadership",
  "Strategy and sustainability",
  "Energy",
  "Insurance",
];

const CMSRoleCard = ({ name, categories, pages, rolesUser }) => {
  const [groupButtons, setGroupButtons] = useState(false);
  const [expandCollapse, setExpandCollapse] = useState(false);
  const [moreLess, setMoreLess] = useState(false);
  const [cmsCheckbox, setCmsCheckbox] = useState(false);
  const [editContactRole, setEditContactRole] = useState(false);
  const [deleteContactRole, setDeleteContactRole] = useState(false);

  const showGroups = cmsGroups.slice(0, 3);
  const showPages = cmsPages.slice(0, 4);

  return (
    <div
      className="cmsRoleCard"
      onMouseEnter={() => setGroupButtons(true)}
      onMouseLeave={() => setGroupButtons(false)}
    >
      <div className="cmsRoleCardInfo">
        <div
          className="align-self-center cmsCustomCheckbox"
          onClick={() => setCmsCheckbox(!cmsCheckbox)}
        >
          <input
            type="checkbox"
            className="checkbox"
            checked={cmsCheckbox}
            onChange={() => setCmsCheckbox(!cmsCheckbox)}
          />
          <span className="checkmark"></span>
        </div>

        <div className="cmsRoleCardCategory cmsRoleCardDetail">
          <p className="cmsRoleName">{name}</p>
          <p className="cmsRoleValue">Practice Area Leadership</p>
        </div>
        <div className="cmsRoleCardGroups cmsRoleCardDetail">
          <p className="cmsRoleName">{categories}</p>
          {showGroups &&
            !expandCollapse &&
            showGroups.map((item, index) => (
              <p key={item} className="cmsRoleValue">
                {item}
              </p>
            ))}
          {cmsGroups &&
            expandCollapse &&
            cmsGroups.map((item, index) => (
              <p key={item} className="cmsRoleValue">
                {item}
              </p>
            ))}
          {!expandCollapse && (
            <button
              className="cmsBtn cms-btn-link"
              type="button"
              onClick={() => setExpandCollapse(!expandCollapse)}
            >
              <span>Expand</span>
              <img src={DownArrow} alt="downarrow" width="7" height="11" />
            </button>
          )}
          {expandCollapse && (
            <button
              className="cmsBtn cms-btn-link"
              type="button"
              onClick={() => setExpandCollapse(!expandCollapse)}
            >
              <span>Collapse</span>
              <img
                src={DownArrow}
                alt="downarrow"
                width="7"
                height="11"
                className="reverse"
              />
            </button>
          )}
        </div>
        <div className="cmsRoleCardPages cmsRoleCardDetail">
          <p className="cmsRoleName">{pages}</p>
          <div className="cmsBadges">
            {rolesUser ? (
              <p className="cmsRoleValue cmsbadge">10 users</p>
            ) : (
              <>
                {showPages &&
                  !moreLess &&
                  showPages.map((item, index) => (
                    <p key={item} className="cmsRoleValue cmsbadge">
                      {item}
                    </p>
                  ))}
                {cmsPages &&
                  moreLess &&
                  cmsPages.map((item, index) => (
                    <p key={item} className="cmsRoleValue cmsbadge">
                      {item}
                    </p>
                  ))}
              </>
            )}
          </div>
          {!rolesUser && !moreLess && (
            <button
              className="cmsBtn cms-btn-link"
              type="button"
              onClick={() => setMoreLess(!moreLess)}
            >
              <span>More</span>
              <img src={DownArrow} alt="downarrow" width="7" height="11" />
            </button>
          )}

          {!rolesUser && moreLess && (
            <button
              className="cmsBtn cms-btn-link"
              type="button"
              onClick={() => setMoreLess(!moreLess)}
            >
              <span>Less</span>
              <img
                src={DownArrow}
                alt="downarrow"
                width="7"
                height="11"
                className="reverse"
              />
            </button>
          )}
        </div>
      </div>
      {groupButtons && (
        <div className="cmsRoleCardActionButtons">
          <button
            type="buton"
            className="edit-icon-btn"
            onClick={() => setEditContactRole(!editContactRole)}
          >
            <img src={EditGreenIcon} alt="edit" width="18" height="18" />
          </button>
          <button
            type="button"
            className="delete-icon-btn"
            onClick={() => setDeleteContactRole(!deleteContactRole)}
          >
            <img src={DeleteIcon} alt="delete" width="14" height="18" />
          </button>
        </div>
      )}
      <CMSModal
        open={editContactRole}
        close={() => setEditContactRole(!editContactRole)}
        className="large-modal"
        size="large"
        modalTitle="EDIT ROLE CATEGORY"
        headerFaIcon={EditWhiteIcon}
      >
        <div className="cms-field-block-with-numbers">
          <span className="position-number">1.</span>
          <CMSInput
            id="name"
            type="text"
            value=""
            placeholder="Enter role category name"
            labelName="Role Category Name"
          />
        </div>
      </CMSModal>
      <CMSModal
        open={deleteContactRole}
        close={() => setDeleteContactRole(!deleteContactRole)}
        size="small"
        className="small-modal"
      >
        <div className="d-flex-only">
          <div className="delete-block">
            <img src={DeleteAlert} alt="delete" width="14" height="18px" />
          </div>

          <p>
            Are you sure you want to delete the selected Role Detail? Once
            deleted this Role Detail will stop appearing on all the Associated
            pages.
          </p>
        </div>
        <div className="cms-button-group">
          <button
            type="button"
            className="cms-default-btn"
            onClick={() => setDeleteContactRole(!deleteContactRole)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="cms-danger-btn"
            onClick={() => setDeleteContactRole(!deleteContactRole)}
          >
            Delete
          </button>
        </div>
      </CMSModal>
    </div>
  );
};

CMSRoleCard.defaultProps = {
  name: "Role Name",
  categories: "Role Category",
  pages: "Role Group displayed on the pages",
};

export default CMSRoleCard;
