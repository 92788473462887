import React from "react";

import CMSTabs from "../../components/CMSTabs";

import "react-tabs/style/react-tabs.css";
import "./style.scss";

import UserIcon from "../../assets/icons/Avatar.png";

const CMSDashboard = () => {
  return (
    <div className="cms-dashboard">
      <header className="cms-header">
        <div className="container">
          <h2>Contact Management Dashboard</h2>
          <div className="cms-user">
            <img src={UserIcon} width="50" height="50" alt="User Icon"/>
            <p>
              <span className="welcome-text">Welcome</span>
              <span className="user-name">Uttam Kumar</span>
            </p>

          </div>
          <div className="spacer"></div>
        </div>
      </header>
      <div className="cms-content">
        <div >
          <CMSTabs />
        </div>
      </div>
    </div>
  );
};

export default CMSDashboard;
