import React, { useCallback, useState } from "react";
import update from 'immutability-helper';
import { Card } from "./Card";

const RoleCategoryList = () => {
  const [cards, setCards] = useState([
    {
      id: 1,
      text: "Practive Area Leadership(14)",
    },
    {
      id: 2,
      text: "Sector & Topic Leadership (12)",
    },
    {
      id: 3,
      text: "Business Leadership (6)",
    },
    {
      id: 4,
      text: "Business Management (3)",
    },
    {
      id: 5,
      text: "Business Nodes (54)",
    },
    {
      id: 6,
      text: "Segment & Topic Leadership (15)",
    },
    {
      id: 7,
      text: "Practice Area Nodes (13)",
    },
    {
        id: 8,
        text: "Topic Team (13)",
      },
  ]);
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      setCards(update(cards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      }));
    },
    [cards]
  );
  const renderCard = (card, index) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        text={card.text}
        moveCard={moveCard}
      />
    );
  };
  return (
    <div className="CMSRolePages-list-group-items">
      {cards.map((card, i) => renderCard(card, i))}
    </div>
  );
};

export default RoleCategoryList;
