import React from "react";
import CMSRoleDetailsCards from "components/CMSRoleDetails/CMSRoleDetailsCards";
import Filters from "components/CMSRole/CMSRoleFilters";

const CMSRoleDetails = () => {
  const roleDetailsFiltersData = {
    name: "ROLE DETAILS",
    searchPlaceholder: "Search by Role details name or roles..",
    filterOne: {
      filterLabel: "ROLE DETAILS",
      filterData: [
        { id: 1, name: "lorem" },
        { id: 2, name: "ipsum" },
      ],
      filterClassName: "roleDetails",
      filterPlaceholder: "Search Role Details",
    },
    filterTwo: {
      filterLabel: "ROLES",
      filterData: [
        { id: 3, name: "dummy" },
        { id: 4, name: "option" },
      ],
      filterClassName: "roles",
      filterPlaceholder: "Search Roles",
    },
    addBtn: "NEW ROLE DETAIL",
    addModal: {
      modalHeader: "ADD NEW ROLE DETAIL",
      addModalFieldFlag: false,
      fieldOne: {
        modalLabel: "Name of Role detail",
        modalLabelPlaceholder: "Enter role detail name..",
      }
    },
    deleteModalMessage:
      "Are you sure you want to delete the selected role detail? Once deleted the role detail information will stop appearing on all contacts.",
  };

  return (
    <>
      <Filters filtersData={roleDetailsFiltersData} />
      <div className="d-flex-only flex-column">
        <CMSRoleDetailsCards />
        <CMSRoleDetailsCards />
        <CMSRoleDetailsCards />
      </div>
    </>
  );
};

export default CMSRoleDetails;
