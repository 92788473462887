import React, { useState } from "react";

import CMSContactFilters from "../../components/CMSContact/CMSContactFilters";
import CMSContactTabel from "../../components/CMSContact/CMSContactTabel";
import ContactInitialIcon from "../../assets/icons/contactInitial.png";

import "./style.scss";

const ContactsContainer = () => {
  const [ selectPraticeArea, setSelectPraticeArea ] = useState({});
  const [ initialSelectPraticeArea, setInitialSelectPraticeArea ] = useState(false);

  return (
    <div className="contacts contacts__container">
      <CMSContactFilters 
        onSelectPracticeArea={obj => {setSelectPraticeArea(obj); setInitialSelectPraticeArea(true);}} 
        initialPaFlag={initialSelectPraticeArea}
        />
      {selectPraticeArea && Object.keys(selectPraticeArea).length ? (
        <CMSContactTabel />
      ) : (
        <div className="contacts__initial d-flex-justify">
          <div className="contacts__initial-block">
            <p>Use the filters provided above to populate relevant contacts</p>
            <img src={ContactInitialIcon} className="" alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactsContainer;
