import React from "react";

import './style.scss'

const CMSAccessManagCard = ({ rows, content }) => {

  const renderTextArea = () => {
    return (
      <textarea className='cms-textarea'>Add Comments…</textarea>
    )
  }


  return (
    <table
      className="cms-table-root"
    >
     <thead className='cms-table-head'>
     <tr className="cms-table-row">
       {rows && rows.map(item => {
         return (
           <th className="cms-table-cell cms-table-cell-head  stickyHeader">
            {item}
           </th>
         )
       })}
       </tr>
     </thead>
     <tbody className="cms-table-body">
       {content && content.map(item => {
         return (
          <tr className="cms-table-row">
            <td className="cms-table-cell type">{item.type}</td>
            <td className="cms-table-cell details">{item.details}</td>
            <td className="cms-table-cell raisedby">{item.raisedBy}</td>
            <td className="cms-table-cell date">{item.date}</td>
            <td className="cms-table-cell status">
            <select className="cms-form-field" defaultValue={item.status} disabled={item.status === 'close'}>
              <option value="open">Open</option>
              <option value="close">Close</option>
            </select></td>
            <td className="cms-table-cell comment">{item.field === 'textarea' && renderTextArea()}</td>
           </tr>
         )
       })}
     </tbody>
    </table>
  );
};

export default CMSAccessManagCard;
