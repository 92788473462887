import React, { useState } from "react";

import ContactsData from "./contacts-data";
import DeleteIcon from "../../assets/icons/Delete-green.svg";
import EditIcon from "../../assets/icons/edit-green.svg";

const CMSContactTableBody = ({ deleteContact, editContact }) => {
  const [groupButtons, setGroupButtons] = useState({});
  const [contactsData, setContactsData] = useState(ContactsData);
  return (
    <tbody className="cms-table-body">
      {contactsData &&
        contactsData.contacts.map((item, index) => {
          return (
            <tr
              className="cms-table-row"
              onMouseEnter={() => setGroupButtons({ [index]: true })}
              onMouseLeave={() => setGroupButtons({ [index]: false })}
              key={item.id}
            >
              <td>
                <label className="check-field  cms-table-cell">
                  <input className="checkbox" type="checkbox" />
                  <span className="checkboxName">{item.name}</span>
                </label>
              </td>
              <td className="cms-table-cell">
                <span>{item.hrTitle}</span>
              </td>
              <td className="cms-table-cell">
                <span>{item.position}</span>
              </td>
              <td className="cms-table-cell">
                <span>{item.email}</span>
              </td>
              <td className="cms-table-cell">
                <span>{item.homeOffice}</span>
              </td>
              <td className="cms-table-cell">
                <span>{item.currentOffice}</span>
              </td>
              <td className="cms-table-cell">
                <span>{item.category}</span>
              </td>
              <td className="cms-table-cell">
                <span>{item.group}</span>
              </td>
              <td className="cms-table-cell">
                <span>{item.role}</span>
              </td>
              <td className="cms-table-cell">
                <span>{item.roleDetails}</span>
              </td>
              {groupButtons[index] && (
                <td className="cms-contact-button-group-floated">
                  <button
                    type="buton"
                    className="edit-icon-btn"
                    onClick={() => editContact()}
                  >
                    <img src={EditIcon} alt="edit" width="18" height="18" />
                  </button>
                  <button
                    type="button"
                    className="delete-icon-btn"
                    onClick={() => deleteContact()}
                  >
                    <img src={DeleteIcon} alt="delete" width="14" height="18" />
                  </button>
                </td>
              )}
            </tr>
          );
        })}
    </tbody>
  );
};

export default CMSContactTableBody;
