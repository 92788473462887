import React, { useState } from "react";
import MultiSelectCheckbox from "components/common/MultiSelectCheckbox";
import InputOnTabs from "components/common/InputOnTabs";
import CMSModal from "../CMSModal";
import CMSInput from "components/common/CMSInput";

import HomeIcon from "assets/icons/home.png";
import FilterIcon from "assets/icons/filter.svg";
import DeleteIcon from "../../assets/icons/Delete.png";
import DownloadIcon from "../../assets/icons/download.svg";
import PlusWhiteIcon from "../../assets/icons/plus-black.svg";
import DeleteAlert from "../../assets/icons/Delete-red.svg";

const Filters = ({ filtersData }) => {
  const [cmsCheckbox, setCmsCheckbox] = useState(false);
  const [addContactRole, setAddContactRole] = useState(false);
  const [deleteContactRole, setDeleteContactRole] = useState(false);
  const {
    name,
    searchPlaceholder,
    filterOne,
    filterTwo,
    addBtn,
    addModal,
    deleteModalMessage,
  } = filtersData;
  function selectedFilter(filterValue, filterName) {}

  return (
    <div className="CMSRole-Headline-container">
      <InputOnTabs placeholder={searchPlaceholder} />
      <div className="d-flex-only">
        <div
          className="align-self-center cmsCustomCheckbox mr17"
          onClick={() => setCmsCheckbox(!cmsCheckbox)}
        >
          <input
            type="checkbox"
            className="checkbox"
            checked={cmsCheckbox}
            onChange={() => setCmsCheckbox(!cmsCheckbox)}
          />
          <span className="checkmark"></span>
        </div>
        <div className="CMSRole-Headline">
          <img src={HomeIcon} alt="home" width="20" height="16" />
          <h2 className="text-uppercase">
            Manage Roles / <span>{name}</span>
          </h2>
        </div>
      </div>
      <div className="CMSRole-Filters d-flex-only">
        <div className="d-flex-align">
          <label className="filter-by-text">
            <img
              src={FilterIcon}
              alt="filter"
              width="14"
              height="11"
              className="filterIcon"
            />
            <span>FILTER BY</span>
          </label>
          <div className="v-divider">|</div>
          <MultiSelectCheckbox
            data={filterOne.filterData}
            labelTxt={filterOne.filterLabel}
            prefillData={[]}
            className={filterOne.filterClassName}
            placeholder={filterOne.filterPlaceholder}
            sendSelectedData={(val) =>
              selectedFilter(val, filterOne.filterClassName)
            }
          />
          <MultiSelectCheckbox
            data={filterTwo.filterData}
            labelTxt={filterTwo.filterLabel}
            prefillData={[]}
            className={filterTwo.filterClassName}
            placeholder={filterTwo.filterPlaceholder}
            sendSelectedData={(val) =>
              selectedFilter(val, filterTwo.filterClassName)
            }
          />
        </div>
        <div className="d-flex-align mr20">
          <img
            src={DeleteIcon}
            alt="delete"
            width="14"
            height="19"
            className="delete-btn"
            onClick={() => setDeleteContactRole(!deleteContactRole)}
          />
          <img
            src={DownloadIcon}
            alt="save"
            width="14"
            height="14"
            className="download-btn"
          />
          <button
            className="button-black"
            onClick={() => setAddContactRole(!addContactRole)}
          >
            <i className="fas fa-plus"></i>
            <span>{addBtn}</span>
          </button>
        </div>
      </div>
      <CMSModal
        open={addContactRole}
        close={() => setAddContactRole(!addContactRole)}
        className="large-modal"
        size="large"
        modalTitle={addModal.modalHeader}
        headerFaIcon={PlusWhiteIcon}
      >
        <div className="cms-field-block-with-numbers">
          <span className="position-number">1.</span>
          <CMSInput
            id="name"
            type="text"
            value=""
            placeholder={addModal.fieldOne.modalLabelPlaceholder}
            labelName={addModal.fieldOne.modalLabel}
          />
        </div>
        {addModal.addModalFieldFlag && <div className="cms-field-block-with-numbers">
          <span className="position-number">2.</span>
          <CMSInput
            id="name"
            type="text"
            value=""
            placeholder={addModal.filedTwo.modalLabelPlaceholder}
            labelName={addModal.filedTwo.modalLabel}
          />
        </div>}
      </CMSModal>
      <CMSModal
        open={deleteContactRole}
        close={() => setDeleteContactRole(!deleteContactRole)}
        size="small"
        className="small-modal"
      >
        <div className="d-flex-only">
          <div className="delete-block">
            <img src={DeleteAlert} alt="delete" width="14" height="18px" />
          </div>
          <p>{deleteModalMessage}</p>
        </div>
        <div className="cms-button-group">
          <button
            type="button"
            className="cms-default-btn"
            onClick={() => setDeleteContactRole(!deleteContactRole)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="cms-danger-btn"
            onClick={() => setDeleteContactRole(!deleteContactRole)}
          >
            Delete
          </button>
        </div>
      </CMSModal>
    </div>
  );
};

export default Filters;
