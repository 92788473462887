import React from "react";
import CMSRoleCards from "components/CMSRole/CMSRoleCard";
import Filters from "components/CMSRole/CMSRoleFilters";
import ReactPaginate from 'react-paginate';
import ArrowRight from 'assets/icons/arrow-right.svg';

const CMSRoleCategory = () => {
  const roleCategoryFiltersData = {
    name: "ROLE CATEGORY",
    searchPlaceholder: "Search by Role Category or Group...",
    filterOne: {
      filterLabel: "ROLE CATEGORY",
      filterData: [
        { id: 1, name: "lorem" },
        { id: 2, name: "ipsum" },
      ],
      filterClassName: "roleCategory",
      filterPlaceholder: "Search Role Category",
    },
    filterTwo: {
      filterLabel: "ROLE GROUP",
      filterData: [
        { id: 3, name: "dummy" },
        { id: 4, name: "option" },
      ],
      filterClassName: "roleGroup",
      filterPlaceholder: "Search Role Group",
    },
    addBtn: "NEW CATEGORY",
    addModal: {
      modalHeader: "ADD NEW ROLE CATEGORY",
      addModalFieldFlag: false,
      fieldOne: {
        modalLabel: "Role Category Name",
        modalLabelPlaceholder: "Add role category name..",
      }
    },
    deleteModalMessage:
      "Are you sure you want to delete the selected role categories? Once deleted the role category will stop appearing on all the associated pages.",
  };
  const ArrowLeftImage = () => {
    return <span>←</span>
  }
  const ArrowRightImage = () => {
    return <span>→</span>
  }
  return (
    <>
      <Filters filtersData={roleCategoryFiltersData} />
      <div className="d-flex-only flex-column">
        <CMSRoleCards name="Role Category Name" categories="Role Groups within category" pages="Role Category is displayed on pages"/>
        <CMSRoleCards name="Role Category Name" categories="Role Groups within category" pages="Role Category is displayed on pages" />
        <CMSRoleCards name="Role Category Name" categories="Role Groups within category" pages="Role Category is displayed on pages" />
        <CMSRoleCards name="Role Category Name" categories="Role Groups within category" pages="Role Category is displayed on pages" />
        <CMSRoleCards name="Role Category Name" categories="Role Groups within category" pages="Role Category is displayed on pages" />
        <CMSRoleCards name="Role Category Name" categories="Role Groups within category" pages="Role Category is displayed on pages" />
        <CMSRoleCards name="Role Category Name" categories="Role Groups within category" pages="Role Category is displayed on pages" />
        <CMSRoleCards name="Role Category Name" categories="Role Groups within category" pages="Role Category is displayed on pages" />
      </div>
      <ReactPaginate
          previousLabel={<ArrowLeftImage/>}
          nextLabel={<ArrowRightImage/>}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={20}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={() => { }}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
    </>
  );
};

export default CMSRoleCategory;
