import React, { useState } from "react";

import CMSModal from "components/CMSModal";
import CMSAddContactForm from "./CMSAddContactForm";
import MultiSelectCheckbox from "../common/MultiSelectCheckbox";

import headerIcon from "../../assets/icons/plus-black.svg";
import DeleteIcon from "../../assets/icons/Delete.png";
import DownloadIcon from "../../assets/icons/download.svg";
import searchIcon from "../../assets/icons/searchIcon.svg";
import FilterIcon from "assets/icons/filter.svg";
import DeleteAlert from "../../assets/icons/Delete-red.svg";

const ContactFilters = ({ onSelectPracticeArea, initialPaFlag }) => {
  const [newContactModalForm, setNewContactModalForm] = useState(false);
  const [deleteContactsModal, setDeleteContactsModal] = useState(false);

  function selectedFilter(filterValue, filterName) {
    console.log("filterName", filterName);
    console.log("filterValue", filterValue);
    if (filterName === "practiceArea") {
      onSelectPracticeArea({ id: 1 });
    }
  }

  return (
    <div className="contacts-filter-actions">
      <div className={`contacts-search ${!initialPaFlag ? "disabled" : ""}`}>
        <img src={searchIcon} className="search-icon" alt="search" />
        <input
          type="text"
          className="contacts-search-input"
          placeholder="Search by name, email address..."
        />
      </div>
      <div className="d-flex-only justify-content-between">
        <div className="filter-groups">
          <div className="d-flex-align">
            <input
              type="checkbox"
              disabled={!initialPaFlag ? true : false}
              className="checkbox"
            />
            <label
              className={`filter-by-text ${!initialPaFlag ? "disabled" : ""} `}
            >
              {/* <i className="fas fa-filter"></i> */}
              <img
                src={FilterIcon}
                alt="filter"
                width="14"
                height="11"
                className="filterIcon"
              />
              <span>FILTER BY</span>
            </label>
          </div>
          <div className="v-divider">|</div>
          {/* <div className="dropdown-btn">
            <span onClick={onPracticeArea}>PRACTICE AREA </span>
            <i className="fas fa-caret-down"></i>
          </div> */}
          <MultiSelectCheckbox
            data={[
              { id: 7, name: "option 1" },
              { id: 8, name: "option 2" },
            ]}
            labelTxt={"PRACTICE AREA"}
            prefillData={[]}
            className={"practiceArea"}
            placeholder={"Search Practice Area"}
            sendSelectedData={() => onSelectPracticeArea({ id: 1 })}
            isSingle={true}
          />
          <MultiSelectCheckbox
            data={[
              { id: 1, name: "lorem" },
              { id: 2, name: "ipsum" },
            ]}
            labelTxt={"POSITION"}
            prefillData={[]}
            className={`position ${!initialPaFlag ? "disabled" : ""}`}
            placeholder={"Search Position"}
            sendSelectedData={(val) => selectedFilter(val, "position")}
          />
          <MultiSelectCheckbox
            data={[
              { id: 3, name: "dummy" },
              { id: 4, name: "option" },
            ]}
            labelTxt={"ROLE CATEGORY"}
            prefillData={[]}
            className={`roleCategory ${!initialPaFlag ? "disabled" : ""}`}
            placeholder={"Search Role Category"}
            sendSelectedData={(val) => selectedFilter(val, "roleCategory")}
          />
          <MultiSelectCheckbox
            data={[
              { id: 5, name: "text1" },
              { id: 6, name: "text2" },
            ]}
            labelTxt={"ROLE GROUP"}
            prefillData={[]}
            className={`roleGroup ${!initialPaFlag ? "disabled" : ""}`}
            placeholder={"Search Role Group"}
            sendSelectedData={(val) => selectedFilter(val, "roleGroup")}
          />
        </div>
        <div className={`d-flex-align ${initialPaFlag ? "mr20" : ""}`}>
          <img
            src={DeleteIcon}
            alt="delete"
            width="14"
            height="19"
            className={`delete-btn ${!initialPaFlag ? "disabled" : ""}`}
            onClick={() => setDeleteContactsModal(!deleteContactsModal)}
          />
          <img
            src={DownloadIcon}
            alt="save"
            width="14"
            height="14"
            className={`download-btn ${!initialPaFlag ? "disabled" : ""}`}
          />
          {initialPaFlag && (
            <button
              className="button-black"
              onClick={() => setNewContactModalForm(!newContactModalForm)}
            >
              <i className="fas fa-plus"></i>
              <span>NEW CONTACT</span>
            </button>
          )}
        </div>
      </div>

      <CMSModal
        open={newContactModalForm}
        close={() => setNewContactModalForm(!newContactModalForm)}
        className="large-modal"
          modalTitle="Associate new contact with Topic page"
          headerFaIcon={headerIcon}
      >
        <CMSAddContactForm />
      </CMSModal>
      <CMSModal
        open={deleteContactsModal}
        close={() => setDeleteContactsModal(!deleteContactsModal)}
        size="small"
        className="small-modal"
      >
        <div className="d-flex-only">
          <div className="delete-block">
            <img src={DeleteAlert} alt="delete" width="14" height="18px" />
          </div>

          <p>
            Are you sure you want to delete the contacts? Once deleted this
            contact will stop appearing on all the associated pages. Please
            confirm to proceed
          </p>
        </div>
        <div className="cms-button-group">
          <button
            type="button"
            className="cms-default-btn"
            onClick={() => setDeleteContactsModal(!deleteContactsModal)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="cms-danger-btn"
            onClick={() => setDeleteContactsModal(!deleteContactsModal)}
          >
            Delete
          </button>
        </div>
      </CMSModal>
    </div>
  );
};

export default ContactFilters;
