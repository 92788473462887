import React from "react";
import MailIcon from "../../assets/icons/mail.svg";
import UserIcon from "../../assets/icons/user.svg";
import LocationIcon from "../../assets/icons/location.svg";

import "./style.scss";

const CMSContactCard = () => {
    return (
        <div className="contactCard">
            <div className="contactCard__profile-block">
                <img src={UserIcon} className="contactCard__profile-img" alt="profile icon" />
                <img src={MailIcon} className="contactCard__profile-email" alt="email icon" />
            </div>
            <div className="contactCard__container">
                <div className="contactCard__details">
                    <div className="contactCard__name">Eric Wick</div>
                    <div className="contactCard__region">RPAL, NAMR</div>
                    <div className="contactCard__designation">Lead Knowledge Analyst</div>
                </div>
                <div className="contactCard__location">
                    <img src={LocationIcon} className="contactCard__location-icon" alt="location icon"/>
                    <div className="contactCard__office-location">München - 08:57 AM</div>
                </div>
            </div>
        </div>
    )
}

export default CMSContactCard;