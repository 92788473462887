import React, { useState } from "react";
import Modal from "react-modal";

const CMSAccessManagementLogs = () => {
  const [detailModal, setDetailModal] = useState(false);
  return (
    <div className="access-management">
      <div className="access-management__heading">LATEST CHANGES</div>
      <div className="access-management__logs-list">
        <p>
          New Role Group is added by sharma.abhishek@bcg.com on 20th June, 2021{" "}
          <button
            className="access-management__logs-list-link"
            type="button"
            onClick={() => setDetailModal(!detailModal)}
          >
            View Details
          </button>
        </p>
        <p>
          New Role Group is added by sharma.abhishek@bcg.com on 20th June, 2021{" "}
          <button
            className="access-management__logs-list-link"
            type="button"
            onClick={() => setDetailModal(!detailModal)}
          >
            View Details
          </button>
        </p>
        <p>
          New Role Group is added by sharma.abhishek@bcg.com on 20th June, 2021{" "}
          <button
            className="access-management__logs-list-link"
            type="button"
            onClick={() => setDetailModal(!detailModal)}
          >
            View Details
          </button>
        </p>
        <p>
          New Role Group is added by sharma.abhishek@bcg.com on 20th June, 2021{" "}
          <button
            className="access-management__logs-list-link"
            type="button"
            onClick={() => setDetailModal(!detailModal)}
          >
            View Details
          </button>
        </p>
        <p>
          New Role Group is added by sharma.abhishek@bcg.com on 20th June, 2021{" "}
          <button
            className="access-management__logs-list-link"
            type="button"
            onClick={() => setDetailModal(!detailModal)}
          >
            View Details
          </button>
        </p>
      </div>
      <Modal
        isOpen={detailModal}
        className="detail-modal"
        ariaHideApp={false}
        onAfterOpen={() => (document.body.style.overflow = "hidden")}
        onAfterClose={() => (document.body.style.overflow = "auto")}
      >
        <div className="cms-react-modal">
          <button
            onClick={() => setDetailModal(!detailModal)}
            type="button"
            className="cms-close-icon"
          >
            <i className="fas fa-times"></i>
          </button>
          <div className="log-details">
            <div className="log-detail-item">
              <span>Admin</span>
              <span>Email</span>
              <span>Date & Time</span>
              <span>Nature of change</span>
            </div>
            <div className="log-detail-item">
              <p>Abhishek</p>
              <p>sharma.abhishek@bcg.com</p>
              <p>12/06/2021 14:30AM</p>
              <p>Added new role category</p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CMSAccessManagementLogs;
