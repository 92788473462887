import React from "react";
import searchIcon from "assets/icons/searchIcon.svg";

import "./style.scss";

const CMSInputWIthIcon = ({ placeholder, type }) => {
  return (
    <div className="input-group">
      <img src={searchIcon} className="search-icon" alt="search" />
      <input
        type={type}
        className="contacts-search-input"
        placeholder={placeholder}
      />
    </div>
  );
};

export default CMSInputWIthIcon;
