import React, { useState } from "react";
import CMSModal from "../CMSModal";
import CMSInput from "components/common/CMSInput";

import DeleteIcon from "../../assets/icons/Delete-green.svg";
import DeleteAlert from "../../assets/icons/Delete-red.svg";
import EditGreenIcon from "../../assets/icons/edit-green.svg";
import EditWhiteIcon from "../../assets/icons/edit-white.svg";

const CMSRoleDetailsCards = () => {
  const [groupButtons, setGroupButtons] = useState(false);
  const [cmsCheckbox, setCmsCheckbox] = useState(false);
  const [editContactRole, setEditContactRole] = useState(false);
  const [deleteContactRole, setDeleteContactRole] = useState(false);

  return (
    <div
      className="cmsRoleCard"
      onMouseEnter={() => setGroupButtons(true)}
      onMouseLeave={() => setGroupButtons(false)}
    >
      <div className="cmsRoleCardInfo">
        <div
          className="align-self-center cmsCustomCheckbox"
          onClick={() => setCmsCheckbox(!cmsCheckbox)}
        >
          <input
            type="checkbox"
            className="checkbox"
            checked={cmsCheckbox}
            onChange={() => setCmsCheckbox(!cmsCheckbox)}
          />
          <span className="checkmark"></span>
        </div>

        <div className="cmsRoleCardCategory cmsRoleCardDetail">
          <p className="cmsRoleName">Role Details Name</p>
          <p className="cmsRoleValue">ESG in Insurance</p>
        </div>
      </div>
      {groupButtons && (
        <div className="cmsRoleCardActionButtons">
          <button
            type="buton"
            className="edit-icon-btn"
            onClick={() => setEditContactRole(!editContactRole)}
          >
            <img src={EditGreenIcon} alt="edit" width="18" height="18" />
          </button>
          <button
            type="button"
            className="delete-icon-btn"
            onClick={() => setDeleteContactRole(!deleteContactRole)}
          >
            <img src={DeleteIcon} alt="delete" width="14" height="18" />
          </button>
        </div>
      )}
      <CMSModal
        open={editContactRole}
        close={() => setEditContactRole(!editContactRole)}
        className="large-modal"
        size="large"
        modalTitle="EDIT ROLE DETAIL"
        headerFaIcon={EditWhiteIcon}
      >
        <div className="cms-field-block-with-numbers">
          <span className="position-number">1.</span>
          <CMSInput
            id="name"
            type="text"
            value=""
            placeholder="Enter role detail name.."
            labelName="Name of Role detail"
          />
        </div>
      </CMSModal>
      <CMSModal
        open={deleteContactRole}
        close={() => setDeleteContactRole(!deleteContactRole)}
        size="small"
        className="small-modal"
      >
        <div className="d-flex-only">
          <div className="delete-block">
            <img src={DeleteAlert} alt="delete" width="14" height="18px" />
          </div>

          <p>
            Are you sure you want to delete the selected role detail? Once
            deleted the role detail information will stop appearing on all
            contacts.
          </p>
        </div>
        <div className="cms-button-group">
          <button
            type="button"
            className="cms-default-btn"
            onClick={() => setDeleteContactRole(!deleteContactRole)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="cms-danger-btn"
            onClick={() => setDeleteContactRole(!deleteContactRole)}
          >
            Delete
          </button>
        </div>
      </CMSModal>
    </div>
  );
};

export default CMSRoleDetailsCards;
