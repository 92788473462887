import React from "react";
import "./style.scss";
const CMSCard = ({ name, description,viewCard }) => {
  return (
    <div className="card" onClick={() => viewCard()}>
      <div className="card-body">
        <h4>{name}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default CMSCard;
