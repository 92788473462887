import React, { useState } from "react";
import CMSRolePages from "components/CMSRoleStructure/CMSRolePages";
import Filters from "components/CMSRoleStructure/filters";

import "./styles.scss";

const CMSRoleStructure = () => {
  const [globalAction, setGlobalAction] = useState(true);
  const [paLevelAction, setPALevellAction] = useState(false);
  const [globalEditAction, setGlobalEditAction] = useState(false);
  const [paLevelEditAction, setPALevelEditlAction] = useState(false);
  return (
    <>
      <Filters
        globalButton={globalAction}
        paLevelButton={paLevelAction}
        globalEditAction={globalEditAction}
        paLevelEditAction={paLevelEditAction}
        globalAction={() => {
          setGlobalAction(true);
          setPALevellAction(false);
        }}
        paAction={() => {
          setGlobalAction(false);
          setPALevellAction(true);
        }}
        editGlobalAction={() => setGlobalEditAction(!globalEditAction)}
      />
      <CMSRolePages
        globalButton={globalAction}
        paLevelButton={paLevelAction}
        globalEditAction={globalEditAction}
      />
    </>
  );
};

export default CMSRoleStructure;
