import React, { useState } from "react";

import HomeIcon from "assets/icons/home.png";
import EditIcon from "../../assets/icons/edit-icon.svg";
import DownloadIcon from "../../assets/icons/download.svg";

const Filters = ({
  globalButton,
  paLevelButton,
  globalEditAction,
  globalAction,
  paAction,
  editGlobalAction,
}) => {
  return (
    <div className="CMSRole-structure-container">
      <div className="d-flex-only">
        <div className="CMSRole-Headline">
          <img src={HomeIcon} alt="home" width="20" height="16" />
          <h2 className="text-uppercase">
            Manage Roles / <span>MANAGE ROLE STRUCTURE</span>
          </h2>
        </div>
      </div>
      <div className="CMSRole-Filters d-flex-only">
        <div className="d-flex-align mr20">
          <button
            className={`${(globalButton && !paLevelButton) ? "button-naro active" : "button-naro"}`}
            onClick={() => globalAction()}
          >
            <span>Global</span>
          </button>
          <button
            className={`${
                (paLevelButton && !globalButton) ? "button-naro active" : "button-naro"
            }`}
            onClick={() => paAction()}
          >
            <span>PA Level</span>
          </button>
          <img
            src={EditIcon}
            alt="edit"
            width="14"
            height="19"
            className={`${globalEditAction ? 'edit-btn disabled': 'edit-btn'}`}
            onClick={() => editGlobalAction()}
          />
          <img
            src={DownloadIcon}
            alt="save"
            width="14"
            height="14"
            className="download-btn"
          />
          {globalEditAction && (
            <>
              <button className="button-black-outline" onClick={() => editGlobalAction()}>
                <span>CANCEL</span>
              </button>
              <button className="button-black" onClick={() => editGlobalAction()}>
                <span>SAVE CHANGES</span>
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Filters;
