import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './MultiSelectCheckbox.scss';

const MultiSelectCheckbox = (props) => {
  const [ openDropdown, setOpenDropdown ] = useState(false);
  const [ filterOptionsData, setFilterOptionsData ] = useState([]);
  const [ selectedFilterObj, setSelectedFilterObj ] = useState(props.prefillData);
  const [ selectedAllCase, setSelectedAllCase ] = useState(true);
  const [ searchFilterValue, setSearchFilterValue ] = useState("");
  const { data, labelTxt, sendSelectedData, prefillData, className, placeholder, isSingle } = props;
  const node = useRef();

  useEffect(() => {
    setSelectedFilterObj(prefillData);
  }, [ prefillData ]);

  useEffect(() => {
    setFilterOptionsData(data);
  }, [ data ]);

  useEffect(() => {
    const listener = (event) => {
      if (!node.current || node.current.contains(event.target)) {
        return;
      }
      setOpenDropdown(false);
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ node, setOpenDropdown ]);

  function handleDropdownClick() {
    !openDropdown && setSelectedFilterObj(prefillData);
    !openDropdown && setSelectedAllCase(prefillData.length === data.length);
    setOpenDropdown(!openDropdown);
  }

  function checkIdPresent(id) {
    return selectedFilterObj.some(function (el) {
      return el.id === id;
    });
  }

  function handleOptionChange(event, obj) {
    let filteredItems = null;
    if (isSingle) {
        filteredItems = [ obj ];
        setSelectedFilterObj(filteredItems);
    } else {
        if (!event.target.checked) {
        filteredItems = selectedFilterObj.filter((item) => item.id !== obj.id);
        } else {
        filteredItems = selectedFilterObj.concat(obj);
        }
        setSelectedAllCase(filteredItems.length === data.length);
        setSelectedFilterObj(filteredItems);
    }
  }

  function selectAllOption(e) {
    if (!e.target.checked) {
      setSelectedAllCase(false);
      setSelectedFilterObj([]);
    } else {
      setSelectedAllCase(true);
      setSelectedFilterObj(data);
    }
  }

  function handleApply() {
    sendSelectedData(selectedFilterObj);
    setOpenDropdown(false);
  }

  function handleClear() {
    setSelectedFilterObj([]);
    setSelectedAllCase(false);
  }

  function searchInputFilter(e) {
    setSearchFilterValue(e.target.value);
    const filteredOptions = data.filter(item => item.name.toLowerCase().indexOf((e.target.value).toLowerCase()) > -1);
    setFilterOptionsData(filteredOptions);
  }

  function removeSearchFilter() {
    setSearchFilterValue("");
    setFilterOptionsData(data);
  }

  function renderOptions(data) {
    return data.map((item) => {
      return (
        <React.Fragment key={ item.name }>
        {isSingle ? (
          <li className={ `multiselect__item singleSelect ${ checkIdPresent(item.id) ? "selected" : "" } ` }
            onClick={ (e) => handleOptionChange(e, item) }
          >
            <span className="optionTxt">{item.name}</span>
          </li>
        ) : (
        <li className="multiselect__checkbox--list" key={ item.name }>
          <label htmlFor={ item.name }>
            <input
              type="checkbox"
              id={ item.name }
              className="checkbox-multiselect"
              checked={ checkIdPresent(item.id) }
              onChange={ (e) => handleOptionChange(e, item) }
            />
            <span className="optionCheckbox">checkbox</span>
            <span className="optionTxt" dangerouslySetInnerHTML={{ __html: item.name }}></span>
          </label>
        </li>
        )}
        </React.Fragment>
      );
    });
  }

  const list = renderOptions(filterOptionsData);

  return (
    <div className={ `multiselect ${ openDropdown ? "showDropdown" : "" } ${ isSingle ? "multiselect__isSingle" : "" } ${className}`} ref={ node }>
        <div className="multiselect__txt" onClick={ handleDropdownClick }>
          {/* <span className="count">{selectedAllCase ? "All" : selectedFilterObj.length }</span> */}
          <span className="label">{ labelTxt }</span>
          <i className={`fas icon ${ openDropdown ? "fa-caret-up" : "fa-caret-down" }`}></i>
        </div>
        <div className="multiselect__listbtn">
          <div className="multiselect__searchFilter">
            <i aria-hidden="true" className="fas fa-filter icon"></i>
            <input type="text" className="inputFilter" placeholder={placeholder} value={searchFilterValue} onChange={ (e) => searchInputFilter(e) }/>
            <i aria-hidden="true" className="fas fa-times-circle icon" onClick={removeSearchFilter}></i>
          </div>
          <ul className={ `multiselect__list` }>
            {!searchFilterValue.length && !isSingle && <li  className="multiselect__checkbox--list">
              <label htmlFor={ labelTxt }>
                <input
                  type="checkbox"
                  id={ labelTxt }
                  className="checkbox-multiselect"
                  checked={ selectedAllCase }
                  onChange={ (e) => selectAllOption(e) }
                />
                <span className="optionCheckbox">checkbox</span>
                <span className="optionTxt">Select All</span>
              </label>
            </li>}
            {list}
          </ul>
          <div className="multiselect__dividerwithbtns">
            <div id="divider"></div>
            <div className="multiselect__btns">
              <button type="button" className="multiselect__apply" disabled={!selectedFilterObj.length} onClick={handleApply}>APPLY</button>
              <button type="button" className="multiselect__clear" disabled={!selectedFilterObj.length} onClick={handleClear}>CLEAR</button>
            </div>
          </div>
        </div>
    </div>
  );
};

MultiSelectCheckbox.defaultProps = {
    isSingle: false,
};

MultiSelectCheckbox.propTypes = {
  data: PropTypes.array,
  prefillData: PropTypes.array,
  labelTxt: PropTypes.string.isRequired,
};

export default MultiSelectCheckbox;
